import { bva } from "$ui/utils"
import type { CardPresenter } from "$ui/card"

export const standard = {
  root: bva('border bg-card text-card-foreground relative flex flex-col', {}),
  header: bva(`flex border-b p-4 relative items-center`, {}),
  footer: bva('flex rounded-b-md bg-board-background gap-2 border-t items-center px-4 py-2', {}),
  content: bva('p-4 grow', {}),
  table: bva('[&_tr>*]:first:ps-4 [&_tr>*]:last:pe-4', {}),
  description: bva('text-sm text-muted', {}),
  title: bva('', {}),
  topState: bva('', {}),
} satisfies CardPresenter
