import { bva } from "$ui/utils"
import type { ButtonPresenter } from "$ui/button"

const base = `
  btn
`
const size = {
  xs: 'btn-xs',
  sm: 'btn-sm',
  default: '',
  lg: 'btn-lg',
}


export const standard = {
  root: bva(base, {
    size,
    variant: {
      primary: 'btn-primary',
      secondary: 'btn-secondary',
      muted: 'btn-muted',
      silence: 'btn-silence',
      destructive: 'btn-danger',
      tint: 'btn-tint',
    },
  })
} satisfies ButtonPresenter


export const light = {
  root: bva(base, {
    size,
    variant: {
      primary: 'btn-primary-lt',
      secondary: 'btn-secondary-lt',
      muted: 'btn-muted-lt',
      silence: 'btn-silence-lt',
      destructive: 'btn-danger-lt',
      tint: 'btn-tint-lt',
    },
  })
} satisfies ButtonPresenter


export const outline = {
  root: bva(base, {
    size,
    variant: {
      primary: 'btn-outline-primary',
      secondary: 'btn-outline-secondary',
      muted: 'btn-outline-muted',
      silence: 'btn-outline-silence',
      destructive: 'btn-outline-danger',
      tint: 'btn-outline-tint',
    },
  })
} satisfies ButtonPresenter


export const ghost = {
  root: bva(base, {
    size,
    variant: {
      primary: 'btn-ghost-primary',
      secondary: 'btn-ghost-secondary',
      destructive: 'btn-ghost-danger',
      muted: 'btn-ghost-muted',
    },
  })
} satisfies ButtonPresenter

declare module '$ui/button' {
  interface ButtonPresenters {
    light: typeof light
    outline: typeof outline
    ghost: typeof ghost
  }
}
