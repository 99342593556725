import { bva } from "$ui/utils"
import type { ContainerPresenter } from "$ui/container"

export const fluid = {
  root: bva('', {}),
} satisfies ContainerPresenter

export const fixed = {
  root: bva('container-xl', {}),
} satisfies ContainerPresenter

declare module '$ui/container' {
  interface ContainerPresenters {
    fixed: typeof fixed
  }
}
