// import "$vendor/vue-flow"
// import "$ui/main.css"

import "./assets/styles.css"
import '$vendor/tabler'
import "$vendor/fontawesome"
import "flatpickr/dist/flatpickr.css"
import "flatpickr/dist/plugins/confirmDate/confirmDate.css"

import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App)

const initializers = import.meta.glob('./initializers/*.ts', { eager: true })
for (const initializer of Object.values(initializers)) {
  (initializer as any).default(app)
}

app.mount('#app')
