import Axios from 'axios'
import type { App } from 'vue'

const axios = Axios.create({
  timeout: 5 * 60 * 1000
})

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: typeof axios
  }
}

export default function(app: App) {
  app.config.globalProperties.$axios = axios
}
