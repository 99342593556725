import { bva } from "$ui/utils"
import type { AlertPresenter } from "$ui/alert"

export const standard = {
  root: bva(`
    relative w-full rounded-lg border px-4 py-3 text-sm
    [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-foreground [&>svg~*]:pl-7
    bg-background text-foreground
  `, {
    variant: {
      primary: 'border-primary/50 text-primary [&>svg]:text-primary',
      secondary: 'border-secondary/50 text-secondary [&>svg]:text-secondary',
      destructive: 'border-destructive/50 text-destructive [&>svg]:text-destructive',
      constructive: 'border-constructive/50 text-constructive [&>svg]:text-constructive',
      muted: 'border-muted/50 text-muted [&>svg]:text-muted',
      silence: 'border-silence/50 text-silence [&>svg]:text-silence',
      tint: '',
    },
  }),
  title: bva(`mb-1 font-medium leading-none tracking-tight`, { }),
  description: bva(`text-sm [&_p]:leading-relaxed`, { }),
} satisfies AlertPresenter
