import type { Account } from '$app/models'
import Cookies from 'js-cookie'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { Cache } from '../lib/Cache'
import createCacheRequest from '$app/lib/createCacheRequest'

export const useSessionStore = defineStore('Session', () => {
  const cache = new Cache()
  const email = ref<string | null>(Cookies.get('session_email') ?? null)

  function update(account: Account) {
    Cookies.set('session_email', account.email)
    email.value = account.email
  }

  function clear() {
    Cookies.remove('session_email')
    email.value = null
    cache.clear()
    cleanup()
  }

  const { request, cleanup } = createCacheRequest()

  const isSignedIn = computed(() => email.value !== null)
  return { email, update, clear, isSignedIn, cache, request, cleanup }
})
